import CryptoJS from 'crypto-js';
import { v4 as uuidv4 } from 'uuid';
import { CRYPTO_SECRET_KEY } from 'src/config';

// Générer un token unique
export const generateToken = () => {
  return uuidv4();
};

// Crypter les données
export const encrypt = (data) => {
  try {
    return CryptoJS.AES.encrypt(data, CRYPTO_SECRET_KEY).toString();
  } catch (error) {
    console.error('Encryption error:', error);
    throw new Error('Failed to encrypt data');
  }
};

// Décrypter les données
export const decrypt = (encryptedData) => {
  try {
    const bytes = CryptoJS.AES.decrypt(encryptedData, CRYPTO_SECRET_KEY);
    return bytes.toString(CryptoJS.enc.Utf8);
  } catch (error) {
    console.error('Decryption error:', error);
    throw new Error('Failed to decrypt data');
  }
};

