import * as Yup from 'yup';
import { merge } from 'lodash';
import PropTypes from 'prop-types';
import { useState, useMemo } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { Form, FormikProvider, useFormik } from 'formik';
// material
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Card,
  Grid,
  Alert,
  Stack,
  Switch,
  Checkbox,
  TextField,
  Typography,
  Autocomplete,
  FormHelperText,
  CircularProgress,
  FormControlLabel,
  Button
} from '@mui/material';
// utils
import { useDispatch, useSelector } from '../../../redux/store';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
//
import Label from '../../../components/Label';
import { createUser, updateUser } from 'src/redux/slices/user';
import { userRoles } from 'src/constants';
import { errorMessage } from '../../../utils/errorHandle';
import UploadMAvatar from '../../../components/upload/UploadMAvatar';
import { CONTACT_TYPE } from '../../../constants/index';
import { displayNameSplite } from '../../../utils/displayNameSplit';
import useAuth from '../../../hooks/useAuth';

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
// ----------------------------------------------------------------------

UserNewForm.propTypes = {
  isEdit: PropTypes.bool,
  currentUser: PropTypes.object
};

const isAdmin = {
  admin: true,
  DRH: false,
  manager: false,
  task: {
    read: true,
    create: true,
    edit: true,
    delete: true
  },
  opportunity: {
    read: true,
    create: true,
    edit: true,
    delete: true
  },
  audience: {
    read: true,
    create: true,
    edit: true,
    delete: true
  },
  stage: {
    read: true,
    create: true,
    edit: true,
    delete: true
  },
  contact: {
    read: true,
    create: true,
    edit: true,
    delete: true
  },
  users: {
    read: true,
    create: true,
    edit: true,
    delete: true
  },
  news: {
    read: true,
    create: true,
    edit: true,
    delete: true
  },
  chat: {
    read: true,
    create: true,
    edit: true,
    delete: true
  },
  document: {
    read: true,
    create: true,
    edit: true,
    delete: true
  },
  client: {
    read: true,
    create: true,
    edit: true,
    delete: true
  },
  manage_persons: {
    read: true,
    create: true,
    edit: true,
    delete: true
  },
  oldTask: {
    read: true,
    create: true,
    edit: true,
    delete: true
  },
  sub_task: {
    read: true,
    create: true,
    edit: true,
    delete: true
  },
  affectation: {
    read: true,
    create: true,
    edit: true,
    delete: true,
    setting: true
  },
  monitoring: {
    read: true
  }
};

export const defaultRole = {
  admin: false,
  DRH: false,
  manager: false,
  task: {
    read: true,
    create: false,
    edit: false,
    delete: false
  },
  opportunity: {
    read: true,
    create: false,
    edit: false,
    delete: false
  },
  audience: {
    read: true,
    create: true,
    edit: true,
    delete: true
  },
  stage: {
    read: true,
    create: true,
    edit: true,
    delete: true
  },
  contact: {
    read: true,
    create: true,
    edit: true,
    delete: true
  },
  users: {
    read: false,
    create: false,
    edit: false,
    delete: false
  },
  news: {
    read: true,
    create: true,
    edit: true,
    delete: true
  },
  chat: {
    read: true,
    create: true,
    edit: true,
    delete: true
  },
  document: {
    read: true,
    create: true,
    edit: true,
    delete: true
  },
  client: {
    read: false,
    create: false,
    edit: false,
    delete: false
  },
  manage_persons: {
    read: false,
    create: false,
    edit: false,
    delete: false
  },
  oldTask: {
    read: false,
    create: false,
    edit: false,
    delete: false
  },
  affectation: {
    setting: false,
    read: false,
    create: false,
    edit: false,
    delete: false
  },
  monitoring: {
    read: false
  }
};

export const defaultGuestRole = {
  admin: false,
  DRH: false,
  manager: false,
  task: {
    read: false,
    create: false,
    edit: false,
    delete: false
  },
  opportunity: {
    read: false,
    create: false,
    edit: false,
    delete: false
  },
  audience: {
    read: false,
    create: false,
    edit: false,
    delete: false
  },
  stage: {
    read: false,
    create: false,
    edit: false,
    delete: false
  },
  contact: {
    read: false,
    create: false,
    edit: false,
    delete: false
  },
  users: {
    read: false,
    create: false,
    edit: false,
    delete: false
  },
  news: {
    read: false,
    create: false,
    edit: false,
    delete: false
  },
  chat: {
    read: false,
    create: false,
    edit: false,
    delete: false
  },
  document: {
    read: true,
    create: true,
    edit: true,
    delete: true
  },
  client: {
    read: false,
    create: false,
    edit: false,
    delete: false
  },
  manage_persons: {
    read: false,
    create: false,
    edit: false,
    delete: false
  },
  oldTask: {
    read: false,
    create: false,
    edit: false,
    delete: false
  },
  affectation: {
    read: false,
    create: false,
    edit: false,
    delete: false
  },
  monitoring: {
    read: false
  }
};

const mergeRole = (currentRole) => {
  return merge(defaultRole, currentRole);
};

export const PASSWORD_TYPE = {
  PRIMARY: 'PRIMARY',
  CONFIRM: 'CONFIRM'
};

const RoleComponent = ({ role, setFieldValue, name, isAdmin = false }) => {
  return (
    <Stack direction="row" flexWrap="wrap" spacing={{ xs: 1, sm: 2 }}>
      {role?.read !== undefined && (
        <FormControlLabel
          labelPlacement="start"
          label="Lire"
          control={
            <Checkbox
              checked={role.read}
              disabled={isAdmin}
              onChange={(value) => setFieldValue(`roles.${name}.read`, value.currentTarget.checked)}
            />
          }
        />
      )}
      {role?.create !== undefined && (
        <FormControlLabel
          labelPlacement="start"
          label="Créer"
          control={
            <Checkbox
              checked={role.create}
              disabled={isAdmin}
              onChange={(value) => setFieldValue(`roles.${name}.create`, value.currentTarget.checked)}
            />
          }
        />
      )}

      {role?.edit !== undefined && (
        <FormControlLabel
          labelPlacement="start"
          label="Editer"
          control={
            <Checkbox
              checked={role.edit}
              disabled={isAdmin}
              onChange={(value) => setFieldValue(`roles.${name}.edit`, value.currentTarget.checked)}
            />
          }
        />
      )}
      {role?.delete !== undefined && (
        <FormControlLabel
          labelPlacement="start"
          label="Supprimer"
          control={
            <Checkbox
              checked={role.delete}
              disabled={isAdmin}
              onChange={(value) => setFieldValue(`roles.${name}.delete`, value.currentTarget.checked)}
            />
          }
        />
      )}
      {role?.setting !== undefined && (
        <FormControlLabel
          labelPlacement="end"
          label="Reglages"
          sx={{ pl: 1.5 }}
          control={
            <Checkbox
              checked={role?.setting || false}
              disabled={isAdmin}
              onChange={(value) => setFieldValue(`roles.${name}.setting`, value.currentTarget.checked)}
            />
          }
        />
      )}
    </Stack>
  );
};

export default function UserNewForm({ isEdit, currentUser }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useAuth();
  const { users, laoding: userLoading } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { error: userError } = useSelector((state) => state.user);

  const [displayPassword, setDisplayPassword] = useState([]);

  const handlePasswordView = (type) => {
    const isDelete = displayPassword?.includes(type);

    if (isDelete) {
      setDisplayPassword((state) => state?.filter((one) => one !== type));
    } else {
      setDisplayPassword((state) => [...state, type]);
    }
  };

  const getPasswordField = (type) => {
    const isIncluded = displayPassword?.includes(type);

    return {
      type: isIncluded ? 'text' : 'password',
      InputProps: {
        endAdornment: !isIncluded ? (
          <VisibilityIcon cursor={'pointer'} onClick={() => handlePasswordView(type)} />
        ) : (
          <VisibilityOffIcon cursor={'pointer'} onClick={() => handlePasswordView(type)} />
        )
      }
    };
  };

  const NewUserSchema = Yup.object().shape({
    email: Yup.string().email('email non valide').required(" L'email est requis "),
    lastName: Yup.string().required(' Le nom est requis '),
    firstName: Yup.string().required(' Le prenom est requis '),
    poste: Yup.string(),
    phoneNumber: Yup.string(),
    password: !isEdit && Yup.string().min(6, 'minimum 6 caractères').required('mot de passe requis'),
    confirmation:
      !isEdit &&
      Yup.string()
        .min(6, 'minimum 6 caractères')
        .required('confirmation requis')
        .oneOf([Yup.ref('password'), null], 'le mot de passe ne correspond pas')
  });

  const names = useMemo(() => {
    if (currentUser?.firstName && currentUser?.lastName) {
      return {
        lastName: currentUser?.lastName,
        firstName: currentUser?.firstName
      };
    }

    if (currentUser?.displayName && currentUser?.displayName.trim() !== '') {
      return displayNameSplite(currentUser?.displayName);
    }

    return { lastName: '', firstName: '' };
  }, [currentUser]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      lastName: names?.lastName || '',
      firstName: names?.firstName || '',
      email: currentUser?.email || '',
      photoUrl: currentUser?.photoUrl || '',
      poste: currentUser?.poste || '',
      phoneNumber: currentUser?.phoneNumber || '',
      password: '',
      confirmation: '',
      typeUser: currentUser?.typeUser || '',
      delete: currentUser?.delete || false,
      active: currentUser?.active || false,
      status: currentUser?.status || null,
      responsables: currentUser?.responsables || [],
      roles: mergeRole(currentUser?.role || {})
    },
    validationSchema: NewUserSchema,
    onSubmit: async (values, { setSubmitting, resetForm, setErrors }) => {
      try {
        setLoading(true);

        const callback = () => {
          resetForm();
          setSubmitting(false);
          setLoading(false);
          enqueueSnackbar(!isEdit ? 'Utilisateur créé avec succès' : 'Utilisateur mise à jour avec succès', {
            variant: 'success'
          });
          navigate(PATH_DASHBOARD.user.list);
        };

        const onError = (err) => {
          setSubmitting(false);
          setLoading(false);
          console.log(err);
          setErrors(errorMessage(err?.code) || "Une erreur s'est produite");
          //console.log(err?.code);
          enqueueSnackbar(
            `une erreur s'est produite ${errorMessage(err?.code) ? ', ' + errorMessage(err?.code) : ''}`,
            { variant: 'error' }
          );
        };

        if (!isEdit) {
          dispatch(
            createUser({
              user: {
                ...values,
                roles: values.roles,
                role: values.roles,
                displayName: values.lastName + ' ' + values.firstName
              },
              callback: callback,
              onError
            })
          );
          return;
        }

        if (isEdit) {
          const { password, confirmation, ...updateValues } = values;
          dispatch(
            updateUser({
              user: {
                id: currentUser.id,
                ...updateValues,
                roles: values.roles,
                role: values.roles,
                displayName: values.lastName + ' ' + values.firstName
              },
              callback: callback
            })
          );
        }
      } catch (error) {
        console.error(error);
        enqueueSnackbar("une erreur s'est produite", { variant: 'error' });
        setLoading(false);
        setSubmitting(false);
        setErrors(errorMessage(error.code) || "Une erreur s'est produite");
      }
    }
  });

  const { errors, values, touched, handleSubmit, setFieldValue, getFieldProps } = formik;

  const handleAdmin = (event) => {
    if (event.currentTarget.checked) {
      setFieldValue('roles', isAdmin);
      return;
    }
    setFieldValue('roles.admin', false);
  };

  const handleManager = (event) => {
    if (event.currentTarget.checked) {
      setFieldValue('roles', isAdmin);
      setFieldValue('roles.manager', true);
      return;
    }

    setFieldValue('roles.manager', false);
  };

  return (
    <FormikProvider value={formik}>
      <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
        {userError && (
          <Stack sx={{ margin: '0 auto', m: 2 }}>
            <Alert severity="error"> {errorMessage(userError?.code) || ''} </Alert>
          </Stack>
        )}
        <Grid container spacing={3}>
          <Grid item xs={12} md={3}>
            <Card sx={{ py: 2, px: 1 }}>
              {isEdit && (
                <Label
                  color={values.active !== 'active' ? 'error' : 'success'}
                  sx={{ textTransform: 'uppercase', position: 'absolute', top: 24, right: 24 }}
                >
                  {values.status}
                </Label>
              )}

              <Box sx={{ mb: 1 }}>
                <UploadMAvatar value={values.photoUrl} onChange={(value) => setFieldValue('photoUrl', value)} />
                <FormHelperText error sx={{ px: 2, textAlign: 'center' }}>
                  {touched.avatarUrl && errors.avatarUrl}
                </FormHelperText>
              </Box>

              <Box sx={{ mt: 2, p: 2 }}>
                <FormControlLabel
                  labelPlacement="start"
                  control={
                    <Switch
                      onChange={(event) => setFieldValue('active', event.target.checked)}
                      checked={values.active}
                    />
                  }
                  label={
                    <>
                      <Typography variant="subtitle2" color="inherit" sx={{ mb: 0.5 }}>
                        Active
                      </Typography>
                    </>
                  }
                  sx={{ mx: 0, mb: 1, width: 1, justifyContent: 'space-between' }}
                />
              </Box>
              {/* <Button variant="contained"> Regenerer un mot de passe </Button> */}
            </Card>
          </Grid>

          <Grid item xs={12} md={4}>
            <Card sx={{ p: 2 }}>
              <Stack>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      fullWidth
                      label="Email "
                      autoComplete="new-email"
                      {...getFieldProps('email')}
                      error={Boolean(touched.email && errors.email)}
                      helperText={touched.email && errors.email}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <TextField
                      fullWidth
                      label="Nom"
                      {...getFieldProps('lastName')}
                      error={Boolean(touched.lastName && errors.lastName)}
                      helperText={touched.lastName && errors.lastName}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <TextField
                      fullWidth
                      label="Prénom"
                      {...getFieldProps('firstName')}
                      error={Boolean(touched.firstName && errors.firstName)}
                      helperText={touched.firstName && errors.firstName}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <TextField
                      select
                      fullWidth
                      value={values.typeUser}
                      label="Type d'utillisateur *"
                      {...getFieldProps('typeUser')}
                      SelectProps={{ native: true }}
                      error={Boolean(touched.typeUser && errors.typeUser)}
                      helperText={touched.typeUser && errors.typeUser}
                    >
                      <option value="" />
                      {Object.entries(CONTACT_TYPE)
                        .filter(([_, o]) => o.title !== 'Client')
                        .map(([key, option]) => {
                          return !option.sub ? (
                            <option key={key} value={option.title}>
                              {option.title === 'Client' ? 'Partenaire' : option.title}
                            </option>
                          ) : (
                            <optgroup key={key} label={option.title}>
                              {Object.entries(option.sub)
                                .filter(([_, s]) => s.title !== 'Equipe')
                                .map(([subKey, subOption]) => (
                                  <option key={`${key}_${subKey}`} value={subOption.title}>
                                    {subOption.title}
                                  </option>
                                ))}
                            </optgroup>
                          );
                        })}
                    </TextField>
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <TextField
                      fullWidth
                      label="Poste"
                      {...getFieldProps('poste')}
                      error={Boolean(touched.poste && errors.poste)}
                      helperText={touched.poste && errors.poste}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <Autocomplete
                      fullWidth
                      multiple
                      value={values.responsables}
                      onChange={(event, newValue) => setFieldValue('responsables', newValue)}
                      options={users}
                      getOptionLabel={(option) => option.displayName}
                      isOptionEqualToValue={(option, value) => {
                        if (option.id === value.id) return true;
                        return false;
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Responsable"
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                {userLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                              </>
                            )
                          }}
                          error={Boolean(touched.responsables && errors.responsables)}
                          helperText={touched.responsables && errors.responsables}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <TextField
                      fullWidth
                      label="Téléphone"
                      {...getFieldProps('phoneNumber')}
                      error={Boolean(touched.phoneNumber && errors.phoneNumber)}
                      helperText={touched.phoneNumber && errors.phoneNumber}
                    />
                  </Grid>

                  {!isEdit && (
                    <Grid item xs={12} sm={12}>
                      <TextField
                        {...getFieldProps('password')}
                        fullWidth
                        label="Mot de passe"
                        type="password"
                        autoComplete="new-password"
                        error={Boolean(touched.password && errors.password)}
                        helperText={touched.password && errors.password}
                        {...getPasswordField(PASSWORD_TYPE.PRIMARY)}
                      />
                    </Grid>
                  )}

                  {!isEdit && (
                    <Grid item xs={12} sm={12}>
                      <TextField
                        {...getFieldProps('confirmation')}
                        fullWidth
                        autoComplete="on"
                        label="Confirmer mot de passe"
                        error={Boolean(touched.confirmation && errors.confirmation)}
                        helperText={touched.confirmation && errors.confirmation}
                        {...getPasswordField(PASSWORD_TYPE.CONFIRM)}
                      />
                    </Grid>
                  )}
                </Grid>

                <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                  <LoadingButton type="submit" variant="contained" loading={loading}>
                    {!isEdit ? 'Nouveau' : 'Mettre à jour'}
                  </LoadingButton>
                </Box>
              </Stack>
            </Card>
          </Grid>

          <Grid item xs={12} md={5}>
            <Card sx={{ p: 1.5 }}>
              <Stack
                spacing={1}
                sx={{ maxHeight: window.innerHeight - 180, overflow: 'auto' }}
                className="scroll-smooth-small"
              >
                <Typography textAlign="center"> Liste des autorisations </Typography>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                  {(user?.role?.superAdmin || user?.role?.manager) && (
                    <FormControlLabel
                      labelPlacement="start"
                      label="Administrateur"
                      disabled={(!user?.role?.manager ?? true) || (!user?.role?.admin ?? true)}
                      control={<Checkbox checked={values.roles.admin} onChange={handleAdmin} />}
                    />
                  )}

                  {(user?.role?.superAdmin || user?.role?.manager) && (
                    <FormControlLabel
                      labelPlacement="start"
                      label="Manager"
                      disabled={!user?.role?.manager ?? true}
                      control={<Checkbox checked={values.roles.manager} onChange={handleManager} />}
                    />
                  )}
                </Stack>

                {/* <Typography color="primary" fontWeight="bold">
                  Audiences
                </Typography>
                <RoleComponent
                  isAdmin={values.roles.admin || values.roles.manager}
                  role={
                    values.roles.audience || {
                      read: false,
                      create: false,
                      edit: false,
                      delete: false
                    }
                  }
                  setFieldValue={setFieldValue}
                  name={userRoles.audience}
                />

                <Typography color="primary" fontWeight="bold">
                  Contacts
                </Typography>
                <RoleComponent
                  isAdmin={values.roles.admin || values.roles.manager}
                  role={
                    values.roles.contact || {
                      read: false,
                      create: false,
                      edit: false,
                      delete: false
                    }
                  }
                  setFieldValue={setFieldValue}
                  name={userRoles.contact}
                /> */}

                <Typography color="primary" fontWeight="bold">
                  Documents
                </Typography>
                <RoleComponent
                  isAdmin={values.roles.admin || values.roles.manager}
                  role={
                    values.roles.document || {
                      read: false,
                      create: false,
                      edit: false,
                      delete: false
                    }
                  }
                  setFieldValue={setFieldValue}
                  name={userRoles.document}
                />

                <Typography color="primary" fontWeight="bold">
                  Projets et tâches
                </Typography>
                <RoleComponent
                  isAdmin={values.roles.admin || values.roles.manager}
                  role={
                    values.roles.task || {
                      read: false,
                      create: false,
                      edit: false,
                      delete: false
                    }
                  }
                  setFieldValue={setFieldValue}
                  name={userRoles.task}
                />

                <Typography color="primary" fontWeight="bold">
                  Utilisateurs
                </Typography>
                <RoleComponent
                  isAdmin={values.roles.admin || values.roles.manager}
                  role={values.roles.users}
                  setFieldValue={setFieldValue}
                  name={userRoles.users}
                />

                <Typography color="primary" fontWeight="bold">
                  Affectation
                </Typography>
                <RoleComponent
                  isAdmin={values.roles.admin || values.roles.manager}
                  role={
                    values.roles.affectation || {
                      read: true,
                      create: true,
                      edit: true,
                      delete: true,
                      setting: false
                    }
                  }
                  setFieldValue={setFieldValue}
                  name={userRoles.affectation}
                />

                {/* <Typography color="primary" fontWeight="bold">
                  Monitoring
                </Typography>
                <RoleComponent
                  isAdmin={values.roles.admin || values.roles.manager}
                  role={
                    values.roles.monitoring || {
                      read: false
                    }
                  }
                  setFieldValue={setFieldValue}
                  name={userRoles.monitoring}
                /> */}
              </Stack>
            </Card>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
}
