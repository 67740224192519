// routes
import { DOC_PATH, MAIL_PATH, PATH_DASHBOARD, PROJECT_PATH } from '../../routes/paths';
import SvgIconStyle from '../../components/SvgIconStyle';
import NotificationLabel from './../../components/NotificationLabel';
import { Icon } from '@iconify/react';
import archiveIcon from '@iconify/icons-entypo/archive';
import { pageName } from 'src/constants/docDriverConstant';
import CommunityNotificationLabel from 'src/components/CommunityNotificationLabel';

// ----------------------------------------------------------------------

export const getIcon = (name, width = '100%', height = '100%', color = null, fontWeight = 'bold') => (
  <SvgIconStyle
    src={`/static/icons/navbar/${name}.svg`}
    sx={{ width: width, height: height, fontWeight: fontWeight, ...(color && { color: color }) }}
  />
);

export const ICONS = {
  blog: getIcon('ic_blog'),
  affect: getIcon('affect'),
  accueil: getIcon('ic_accueil'),
  contact: <Icon icon="fluent:book-contacts-32-filled" height={30} />,
  taches: getIcon('ic_taches'),
  taches_old: getIcon('ic_task_old'),
  opportunity: getIcon('ic_opportunity'),
  gestion_personnel: getIcon('ic_gestion_personnel'),
  document: <Icon icon="bi:folder-fill" height={30} />,
  news: getIcon('ic_news'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  booking: getIcon('ic_booking'),
  clients: getIcon('ic_client'),
  framework: getIcon('ic_handshake'),
  wallet: getIcon('ic_collection'),
  clock: getIcon('ic_clock'),
  report: getIcon('ic_report'),
  notification: getIcon('notification_ic'),
  task: <Icon icon="fluent:task-list-square-person-20-filled" height={30} />,
  archive: <Icon icon={archiveIcon} {...{ height: 50, width: 50 }} />
};

const sidebarConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    items: [
      { title: 'Documents', path: DOC_PATH.root, icon: ICONS.document },
      {
        title: 'Communauté',
        path: PATH_DASHBOARD.general.app,
        icon: ICONS.dashboard,
        info: <CommunityNotificationLabel />
      },
      { title: 'Projets', path: PATH_DASHBOARD.general.tasks, icon: ICONS.kanban }

      // { title: 'Tâches', path: PATH_DASHBOARD.general.taskRecap.root, icon: ICONS.task },
      // { title: 'Affectation', path: PATH_DASHBOARD.general.affectation, icon: ICONS.affect },
      // { title: 'Contacts', path: PATH_DASHBOARD.general.contacts.root, icon: ICONS.contact },
      // { title: 'Audience', path: PATH_DASHBOARD.general.accueil.root, icon: ICONS.banking },
      // { title: 'Actions de projets', path: PATH_DASHBOARD.general.oldtask.root, icon: ICONS.taches_old },
      // { title: 'Calendrier', path: PATH_DASHBOARD.calendar, icon: ICONS.calendar },
      // { title: 'Workflow', path: PATH_DASHBOARD.general.workflow.root, icon: ICONS.analytics },
      // { title: 'Gestion et suivi', path: PATH_DASHBOARD.general.gestionSuivit.root, icon: ICONS.framework },
      // { title: 'Mail', path: PATH_DASHBOARD.mail.root, icon: ICONS.mail },
      // {
      //   title: 'Archives',
      //   path: PATH_DASHBOARD.general.archive.root,
      //   icon: ICONS.archive
      // }
    ]
  },
  {
    items: [
      {
        title: 'Activités',
        path: PATH_DASHBOARD.notifications.root,
        icon: ICONS.notification,
        info: <NotificationLabel />
      }
    ]
  },
  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: 'Management',
    items: [
      // MANAGEMENT : USER
      {
        title: 'Utilisateurs',
        path: PATH_DASHBOARD.user.list,
        icon: ICONS.user
      }
      // {
      //   title: 'monitoring',
      //   path: PATH_DASHBOARD.monitoring.root,
      //   icon: ICONS.analytics
      // }
    ]
  }
];

export const docSidebarConfig = [
  {
    subheader: 'documents',
    items: [
      { title: pageName.RECENT, path: DOC_PATH.recent, icon: <Icon icon="mdi:recent" /> },
      { title: pageName.MYFOLDERS, path: DOC_PATH.folders, icon: <Icon icon="eva:folder-fill" /> },
      { title: pageName.TAGS, path: DOC_PATH.tags, icon: <Icon icon="eva:pricetags-fill" rotate={1} /> },
      { title: pageName.MYSHARES, path: DOC_PATH.myShare, icon: <Icon icon="ri:share-fill" /> },
      { title: pageName.SHAREWITHME, path: DOC_PATH.shareWithMe, icon: <Icon icon="clarity:file-share-solid" /> },
      { title: pageName.AUTH, path: DOC_PATH.auth, icon: <Icon icon="eva:lock-fill" /> },
      { title: pageName.TRASH, path: DOC_PATH.trash, icon: <Icon icon="heroicons:trash" /> }
      // { title: pageName.SHARED_LINKS, path: DOC_PATH.sharedLinks, icon: <Icon icon="ic:outline-link" /> }
    ]
  }
];

export const projectSidebarConfig = [
  {
    subheader: 'projets',
    items: [
      { title: 'Projets', path: PROJECT_PATH.projects, icon: ICONS.kanban },
      {
        title: 'Mes tâches',
        path: PROJECT_PATH.tasks,
        icon: <Icon icon="fluent:task-list-square-person-20-filled" height={25} />
      },
      { title: 'Agenda', path: PROJECT_PATH.agenda, icon: <Icon icon="uim:calender" height={25} /> }
    ]
  }
];

export default sidebarConfig;
