import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { Container, Alert, AlertTitle, Stack } from '@mui/material';
import useAuth from './../hooks/useAuth';
import LoadingView from 'src/components/LoadingView';
// ----------------------------------------------------------------------

RoleBasedGuard.propTypes = {
  role: PropTypes.string,
  action: PropTypes.string,
  children: PropTypes.node
};

export default function RoleBasedGuard({ superAdmin = null, role, action, free = false, children }) {
  const {
    user: { role: userRole }
  } = useAuth();
  const { user } = useAuth();

  const isAccessible = useMemo(() => {
    // superAdmin !== null ? superAdmin : userRole?.admin || userRole[role] ? userRole[role][action] : false;
    if (superAdmin !== null) {
      return superAdmin;
    }

    if (userRole?.manager) {
      return true;
    }

    if (userRole?.admin) {
      return true;
    }

    if (free) {
      return true;
    }

    if (userRole[role]) {
      return userRole[role][action];
    }

    return false;
  }, [superAdmin, role, action, userRole, free]);

  if (Object.keys(userRole).length === 0) {
    return (
      <Container>
        <Stack justifyContent="center" alignItems="center">
          <LoadingView />
        </Stack>
      </Container>
    );
  }

  if (!isAccessible) {
    return (
      <Container sx={{ p: 3 }}>
        <Alert severity="error">
          <AlertTitle>Permission refusée</AlertTitle>
          Vous n'avez pas accès à cette page
        </Alert>
      </Container>
    );
  }

  return <>{children}</>;
}
