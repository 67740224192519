import React, { createContext, useContext, useEffect, useState } from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import UploadingOneFileProgress from 'src/components/UploadingOneFileProgress';
import { pageName } from 'src/constants/docDriverConstant';
import useAuth from 'src/hooks/useAuth';
import useLocalStorage from 'src/hooks/useLocalStorage';
import {
  docDriverCreateFile,
  docDriverCreateMyFolder,
  multipleFilesSave,
  updateDocDriverFileOrFolderField
} from 'src/redux/slices/document';
import { useDispatch } from 'src/redux/store';
import { DOC_PATH } from 'src/routes/paths';
import { FolderType } from 'src/section/doc/types';
import { useNavigate } from 'react-router';
import { useToggleV2 } from 'src/hooks/useToggle';
import Reader from 'src/components/Reader';

const DocDriverProvider = createContext({
  userId: null,
  currentPage: '',
  /** @type {FolderType} */
  currentFolder: null,
  renameItem: null,
  checked: [],
  /** @type {React.Dispatch<React.SetStateAction<FolderType>>} */
  setCurrentFolder: () => {},
  setChecked: (selected) => {},
  setRenameItem: (item) => {},
  setPage: (pageName) => {},
  getTitle: (pathname) => {},
  /** @type {FolderType} */
  baseFolder: null,
  addToTrash: (id = []) => {},
  addToFavorit: (id = [], value) => {},
  updateName: (id, name) => {},
  pageIcon: (title) => {},
  createFile: (files, callback) => {},
  createFolder: (name, callback) => {},
  openFolder: (folderId) => {},
  openFile: (file) => {},
  isDetailOpen: false,
  openDetails: (element) => {},
  openComment: (element) => {},
  hideDetails: () => {},
  selectedEl: null
});

const pageIcon = (title) => {
  if (title === pageName.RECENT) return 'eva:clock-outline';
  if (title === pageName.MYSHARES) return 'eva:share-outline';
  if (title === pageName.MYFOLDERS) return 'eva:folder-outline';
  if (title === pageName.SHAREWITHME) return 'clarity:file-share-solid';
  if (title === pageName.SHARED_LINKS) return 'ic:outline-link';
  if (title === pageName.TRASH) return 'heroicons:trash';
  return '';
};

const getTitle = (pathname) => {
  const match = (path) => (path ? !!matchPath({ path, end: false }, pathname) : false);
  if (match(DOC_PATH.folders)) return pageName.MYFOLDERS;
  if (match(DOC_PATH.shareWithMe)) return pageName.SHAREWITHME;
  if (match(DOC_PATH.sharedLinks)) return pageName.SHARED_LINKS;
  if (match(DOC_PATH.recent)) return pageName.RECENT;
  if (match(DOC_PATH.myShare)) return pageName.MYSHARES;
  if (match(DOC_PATH.trash)) return pageName.TRASH;
  return '';
};

export const useDocDriverContext = () => {
  return useContext(DocDriverProvider);
};

export default function DocDriverContext({ children }) {
  const navigate = useNavigate();

  const {
    user: { id, displayName, email, photoURL }
  } = useAuth();
  /** @type {FolderType} */
  const baseFolder = {
    name: pageName.MYFOLDERS,
    id: id,
    path: [{ name: pageName.MYFOLDERS, id, path: [] }]
  };
  const [currentFolder, setCurrentFolder] = useState(baseFolder);
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const [currentPage, setPage] = useLocalStorage('docPage', '');
  const [uploadingFiles, setUploadingFiles] = useState([]);
  const [renameItem, setRenameItem] = useState(null);
  const [checked, setChecked] = React.useState([]);

  const [selectedFile, setSelectedFile] = React.useState(null);
  const [selectedEl, setSelectedEl] = React.useState(null);

  const [visible, show, hide] = useToggleV2();
  const [visibleC, showC, hideC] = useToggleV2();

  const [isDetailOpen, openDetails, hideDetails] = useToggleV2();

  useEffect(() => {
    setPage(getTitle(pathname));
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const createFolder = (name, callback) => {
    dispatch(
      docDriverCreateMyFolder({
        folder: {
          name,
          myFolders: true,
          parentId: currentFolder?.id || id,
          path: [
            ...(currentFolder?.path || []),
            {
              name: currentFolder?.name || baseFolder.name,
              id: currentFolder?.id || baseFolder.id,
              path: currentFolder?.path || baseFolder.path
            }
          ]
        },
        user: {
          id,
          email,
          name: displayName,
          avatar: photoURL || null
        },
        callback: callback
      })
    );
  };

  const createFile = (files, callback) => {
    const filesPathRoot = currentFolder.id || id;
    const onSave = (data = []) => {
      const uploadFiles = data.map((file) => ({
        name: file.name,
        id: file.id,
        parentId: currentFolder.id,
        file: file
      }));

      dispatch(
        docDriverCreateFile({
          files: uploadFiles,
          user: {
            id,
            email,
            name: displayName,
            avatar: photoURL || null
          },
          callback: callback
        })
      );
    };
    multipleFilesSave(files, onSave, null, setUploadingFiles, filesPathRoot);
  };

  const updateName = (_id, name) => {
    setChecked([]);
    dispatch(updateDocDriverFileOrFolderField({ id: _id, field: 'name', value: name }));
  };

  const addToFavorit = (_ids, value) => {
    setChecked([]);
    dispatch(updateDocDriverFileOrFolderField({ id: _ids, field: 'favorite', value: value }));
  };

  const addToTrash = (_id) => {
    setChecked([]);
    dispatch(updateDocDriverFileOrFolderField({ id: _id, field: 'isDelete', value: true }));
  };

  const openFolder = (folderId) => {
    navigate(`${DOC_PATH.folders}/${folderId}`);
  };

  const openFile = (file) => {
    setSelectedFile(file);
    show();
    setChecked([]);
  };

  const handleReaderClosing = () => {
    hide();
    setSelectedFile(null);
    setChecked([]);
  };

  const handleOpenDetails = (element) => {
    setSelectedEl(element);
    show();
    setChecked([]);
    openDetails();
  };

  const handleCommentsClosing = () => {
    hideC();
    setSelectedFile(null);
    setChecked([]);
  };

  const openComment = (element) => {
    setSelectedEl(element);
    showC();
    setChecked([]);
  };

  const store = {
    userId: id,
    setPage,
    getTitle,
    checked,
    pageIcon,
    baseFolder,
    currentPage,
    currentFolder,
    createFile,
    updateName,
    addToTrash,
    renameItem,
    addToFavorit,
    setChecked,
    setRenameItem,
    createFolder,
    setCurrentFolder,
    openFolder,
    openFile,
    isDetailOpen,
    openDetails: handleOpenDetails,
    hideDetails,
    selectedEl,
    openComment
  };

  return (
    <DocDriverProvider.Provider value={store}>
      {children}
      {uploadingFiles.length > 0 &&
        uploadingFiles.map((upload) => <UploadingOneFileProgress key={upload.id} uploading={upload} />)}
      {visible && (
        <Reader open={visible} onClose={handleReaderClosing} file={selectedFile?.file} url={selectedFile?.file?.url} />
      )}
    </DocDriverProvider.Provider>
  );
}
