import { createSlice } from '@reduxjs/toolkit';
import { auth } from 'src/contexts/FirebaseContext';
import { decrypt } from 'src/utils/crypto';
import firestore from 'src/utils/firestore';

export const SHARED_DOCUMENT_ACCESS_STATUS = {
  LOADING: 'loading',
  INVALID_LINK: 'invalid_link',
  UNAUTHORIZED: 'unauthorized',
  NOT_FOUND: 'not_found',
  ERROR: 'error',
  SUCCESS: 'success'
};

const initialState = {
  currentDocument: null,
  documentId: null,
  isLoading: false,
  accessRules: null,
  status: null,
  error: null
};

const slice = createSlice({
  name: 'documentView',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
      state.status = null;
      state.error = null;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    setStatus(state, action) {
      state.status = action.payload;
    },
    setDocumentView(state, action) {
      state.currentDocument = action.payload;
      state.isLoading = false;
    },
    setDocumentId(state, action) {
      state.documentId = action.payload;
      state.isLoading = false;
    },
    setAccessRules(state, action) {
      state.accessRules = action.payload;
      state.isLoading = false;
    },
    clearDocumentView(state) {
      state.currentDocument = null;
      state.isLoading = false;
      state.error = null;
    }
  }
});

export default slice.reducer;

// Actions
export const { setDocumentView, clearDocumentView } = slice.actions;

// Thunks
export function setCurrentDocument(document) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startLoading());
      dispatch(slice.actions.setDocumentView(document));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getDocumentByToken(token) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startLoading());
      const user = auth.currentUser;
      const snap = await firestore
        .collection('share_links')
        .where('token', '==', token)
        .where('isActive', '==', true)
        .get();

      if (snap.empty) {
        return dispatch(slice.actions.setStatus(SHARED_DOCUMENT_ACCESS_STATUS.INVALID_LINK));
      }

      const linkData = snap.docs[0].data();
      const decryptedData = JSON.parse(decrypt(linkData.encryptedData));
      const { accessRules: rules, documentId: docId } = decryptedData;

      if (!rules.allowedAllUsers && !rules.allowedUsers.includes(user?.email)) {
        return dispatch(slice.actions.setStatus(SHARED_DOCUMENT_ACCESS_STATUS.UNAUTHORIZED));
      }

      dispatch(slice.actions.setDocumentId(docId));
      dispatch(slice.actions.setAccessRules(rules));
      dispatch(slice.actions.setStatus(SHARED_DOCUMENT_ACCESS_STATUS.SUCCESS));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.setStatus(SHARED_DOCUMENT_ACCESS_STATUS.ERROR));
      dispatch(slice.actions.hasError(SHARED_DOCUMENT_ACCESS_STATUS.ERROR));
    }
  };
}
