import { createSlice } from '@reduxjs/toolkit';
import { firestore } from 'src/contexts/FirebaseContext';
import { serverTime } from 'src/utils/serverTime';
import { generateToken, encrypt } from 'src/utils/crypto';
import { createMail } from './notifications';
import { SHARE_BASE_URL, SHARE_PATH } from 'src/config';

const initialState = {
  isLoading: false,
  error: null,
  links: {}
};

const slice = createSlice({
  name: 'shareLinks',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    getLinksSuccess(state, action) {
      const { documentId, links } = action.payload;
      state.links[documentId] = links;
      state.isLoading = false;
    },
    addLinkSuccess(state, action) {
      const { documentId, link } = action.payload;
      if (!state.links[documentId]) {
        state.links[documentId] = [];
      }
      state.links[documentId].push(link);
      state.isLoading = false;
    },
    updateLinkSuccess(state, action) {
      const { documentId, link } = action.payload;
      const linkIndex = state.links[documentId]?.findIndex(l => l.id === link.id);
      if (linkIndex !== -1) {
        state.links[documentId][linkIndex] = link;
      }
      state.isLoading = false;
    },
    revokeLinkSuccess(state, action) {
      const { documentId, linkId } = action.payload;
      state.links[documentId] = state.links[documentId]?.filter(link => link.id !== linkId) || [];
      state.isLoading = false;
    }
  }
});

export default slice.reducer;

const { actions } = slice;

export const shareLinkReference = firestore.collection('share_links');

const areAccessRulesEqual = (rules1, rules2) => {
  // Vérifie si les deux liens sont publics ou privés
  if (rules1.allowedAllUsers !== rules2.allowedAllUsers) {
    return false;
  }

  // Vérifie les permissions
  if (rules1.permissions.key !== rules2.permissions.key) {
    return false;
  }

  // Si ce sont des liens privés, vérifie les utilisateurs autorisés
  if (!rules1.allowedAllUsers && rules1.allowedUsers && rules2.allowedUsers) {
    const users1 = [...rules1.allowedUsers].sort();
    const users2 = [...rules2.allowedUsers].sort();
    return JSON.stringify(users1) === JSON.stringify(users2);
  }

  return true;
};

export const createShareLink = ({ documentId, accessRules, userId, onSuccess, onError }) => {
  return async (dispatch) => {
    try {
      dispatch(actions.startLoading());

      // Vérifie s'il existe déjà un lien actif similaire
      const existingLinksSnapshot = await shareLinkReference
        .where('documentId', '==', documentId)
        .where('isActive', '==', true)
        .get();

      const existingLinks = existingLinksSnapshot.docs.map(doc => doc.data());
      const similarLinkExists = existingLinks.some(link => 
        areAccessRulesEqual(link.accessRules, accessRules)
      );

      if (similarLinkExists) {
        dispatch(actions.hasError('Un lien de partage avec les mêmes caractéristiques existe déjà'));
        if (onError) onError('Un lien de partage avec les mêmes caractéristiques existe déjà');
        return;
      }
      
      const token = generateToken();
      const shareUrl = `${SHARE_BASE_URL}${SHARE_PATH}/${token}`;
      
      const sensitiveData = {
        documentId,
        accessRules,
        creatorId: userId,
        createdAt: new Date().toISOString()
      };
      const encryptedData = encrypt(JSON.stringify(sensitiveData));

      const linkRef = shareLinkReference.doc();
      
      const linkData = {
        id: linkRef.id,
        documentId,
        token,
        encryptedData,
        url: shareUrl,
        creatorId: userId,
        createdAt: serverTime(),
        updatedAt: serverTime(),
        isActive: true,
        accessRules
      };

      await linkRef.set(linkData);

      dispatch(actions.addLinkSuccess({ documentId, link: linkData }));

      if (accessRules.allowedUsers?.length) {
        dispatch(createMail({
          users: accessRules.allowedUsers,
          subject: 'Lien de partage de document',
          description: 'Un document vous a été partagé avec le lien suivant :',
          link: shareUrl
        }));
      }
      
      if (onSuccess) onSuccess(linkData);
    } catch (error) {
      console.error(error);
      dispatch(actions.hasError(error));
      if (onError) onError(error);
    }
  };
};

export const getDocumentShareLinks = ({ documentId, onSuccess, onError }) => {
  return async (dispatch) => {
    try {
      dispatch(actions.startLoading());
      
      const linksSnapshot = await shareLinkReference
        .where('documentId', '==', documentId)
        .where('isActive', '==', true)
        .get();
        
      const links = linksSnapshot.docs.map(doc => doc.data());
      
      dispatch(actions.getLinksSuccess({ documentId, links }));
      
      if (onSuccess) onSuccess(links);
    } catch (error) {
      console.error(error);
      dispatch(actions.hasError(error));
      if (onError) onError(error);
    }
  };
};

export const updateShareLink = ({ linkId, accessRules, onSuccess, onError }) => {
  return async (dispatch) => {
    try {
      dispatch(actions.startLoading());
      
      const linkRef = shareLinkReference.doc(linkId);
      
      await linkRef.update({
        accessRules,
        updatedAt: serverTime()
      });

      const updatedDoc = await linkRef.get();
      const updatedLink = updatedDoc.data();
      
      dispatch(actions.updateLinkSuccess({ documentId: updatedLink.documentId, link: updatedLink }));
      
      if (onSuccess) onSuccess(updatedLink);
    } catch (error) {
      console.error(error);
      dispatch(actions.hasError(error));
      if (onError) onError(error);
    }
  };
};

export const revokeShareLink = ({ linkId, documentId, onSuccess, onError }) => {
  return async (dispatch) => {
    try {
      dispatch(actions.startLoading());
      
      const linkRef = shareLinkReference.doc(linkId);
      
      await linkRef.update({
        isActive: false,
        updatedAt: serverTime()
      });

      dispatch(actions.revokeLinkSuccess({ documentId, linkId }));
      
      if (onSuccess) onSuccess();
    } catch (error) {
      console.error(error);
      dispatch(actions.hasError(error));
      if (onError) onError(error);
    }
  };
}; 